<template>
  <div>
    <div
      v-if="isReady"
      :style="`background-image: url('${
        $root.event && $root.event.pageSettings
          ? $root.event.pageSettings.bgImgUrl
          : defaultBackgroundImg
      }')`"
      class="landing"
    >
      <div v-if="$root.event" class="container is-max-widescreen">
        <div style="display: flex">
          <div class="columns is-centered" style="width: 100%">
            <!-- eslint-disable max-len -->
            <div
              v-if="
                $root.event.pageSettings &&
                ($root.event.pageSettings.logoUrl ||
                  $root.event.pageSettings.landingContent)
              "
              class="column is-half-tablet is-three-fifths-fullhd px-5"
            >
              <img
                v-if="
                  $root.event.pageSettings && $root.event.pageSettings.logoUrl
                "
                :src="$root.event.pageSettings.logoUrl"
              />
              <div
                class="mt-1 mb-3"
                v-html="$root.event.pageSettings.landingContent"
              />
            </div>

            <div
              :class="[
                $root.event.pageSettings &&
                ($root.event.pageSettings.logoUrl ||
                  $root.event.pageSettings.landingContent)
                  ? ' is-half-tablet is-two-fifths-fullhd'
                  : 'is-full-tablet is-three-fifths-fullhd no-event-content',
              ]"
              class="column"
            >
              <!--
              <h1 class="title has-text-centered">
                {{ $root.event.title }}
              </h1>
              -->

              <div class="card">
                <div class="card-content">
                  <h3 class="is-size-5 has-text-weight-bold title">
                    {{ $t('registration') }}
                  </h3>
                  <FormBase />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-event-fount">{{ $t('no_event_found') }}</div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable prefer-destructuring */
/* eslint comma-dangle: [2, "never"] */
/* eslint arrow-parens: [2, "as-needed"] */
import { localize } from 'vee-validate';
import FormBase from '@/components/regForm/FormBase.vue';
import httpClient from '@/api/client';
import { loadLanguageAsync } from '@/utils/i18n-setup';

export default {
  name: 'HomePage',
  components: {
    FormBase
  },
  data() {
    return {
      isReady: false,
      isLoading: true,
      selectedLang: 'en',
      defaultBackgroundImg: ''
    };
  },
  async created() {
    try {
      const res = await httpClient.get(
        `/event/${this.$router.currentRoute.params.slug}`
      );

      this.$root.event = res.data.event;

      const checkField = res.data.event.regForm.find(
        v => v.name === 'Uzmanlık Diğer'
      );
      checkField.required = true;
      if (checkField) {
        checkField.checkController = {
          field: 'Uzmanlık',
          value: 'Diğer'
        };
      }

      this.$root.event.regForm.splice(
        res.data.event.regForm.indexOf(v => v.name === 'Uzmanlık Diğer'),
        0,
        {
          required: true,
          name: 'Uzmanlığınızı Giriniz',
          type: 'short_text',
          is_shown: true,
          helpText: '',
          order: checkField.order,
          checkController: {
            field: 'Uzmanlık Diğer',
            value: true
          }
        }
      );
      document.title = res.data.event.title;
      this.selectedLang = res.data.event.interfaceLang;
      this.updateSelectedLang();
      this.isReady = true;
      this.isLoading = false;
    } catch (error) {
      console.log('=>', error);
      this.isReady = true;
      this.isLoading = false;
    }
  },
  watch: {
    event(val) {
      console.log(val);
    }
  },
  mounted() {},
  methods: {
    updateSelectedLang() {
      loadLanguageAsync(this.selectedLang);
      localize(this.selectedLang);
    }
  }
};
</script>
<style>
html {
  background-color: #f5f5f5;
}
.columns {
  margin-top: 128px;
}

.no-event-content {
  max-width: 830px !important;
  margin: auto;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.label,
.control-label {
  font-size: 0.875rem !important;
}

.field-content p {
  font-size: 0.875rem !important;
}

.field-content h6 {
  font-size: 1rem !important;
}

.field-content h5 {
  font-size: 1.25rem !important;
}

.field-content h4 {
  font-size: 1.5rem !important;
}

.field-content h3 {
  font-size: 1.75rem !important;
}
.field-content h2 {
  font-size: 2rem !important;
}

.field-content h1 {
  font-size: 2.25rem !important;
}

.landing {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0%;
  background-attachment: fixed;
}
.landing .select,
.landing .select select {
  width: 100% !important;
}
.no-event-fount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
</style>
