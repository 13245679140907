import httpClient from '@/api/client';

export const register = async (event, data) => {
  if (event.type === 'zoomwebinar') {
    return httpClient.post('zoom-registration', data);
  }
  return httpClient.post('registration', data);
};

export const createEventUrl = (event, registration) => {
  const { type: eventType } = event;
  const baseUrlList = {
    theatre: 'https://stage.livecasthq.com',
    webinar: 'https://webinar.livecasthq.com',
    live: 'https://live.livecasthq.com',
    irwebcast: 'https://irwebcast.livecasthq.com',
  };

  if (eventType === 'zoomwebinar') {
    return registration.eventUrl;
  }

  if (eventType === 'custom') {
    return `${event.customLiveUrl}/${event._id}?t=${registration.token}`;
  }

  const baseUrl = baseUrlList[event.type];
  return `${baseUrl}/${event._id}?t=${registration.token}`;
};
